*,
:after,
:before {
	box-sizing: border-box;
	margin: 0;
	position: relative;
}

html,
body {
	font-weight: var(--global-font-weight);
	font-size: var(--global-font-size);
	font-family: var(--global-font-family);
	-webkit-font-smoothing: antialiased;
	line-height: 1.5em;
	color: var(--global-font-color);
	background: var(--global-background);
	&[data-menu-open="true"] {
		overflow: hidden;
	}
}
*::selection {
	background: var(--selection-background);
	color: var(--selection-color);
}
svg,
image {
	max-width: 100%;
}
#__next {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	max-width: 100vw;
}
