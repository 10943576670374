.blogLayout {
	padding: 4rem 0;
}
.stageImage {
	@media (min-width: 80rem) {
		border-radius: var(--media-border-radius);
	}
	width: 100%;
}
.imageContainer {
	margin: 0 auto;
	width: 100%;
	max-width: 80rem;
	& > span {
		width: 100%;
	}
}
