html[data-theme="dark"] {
	// Globals ========================================================================== */
	--global-font-color: var(--dark-gray-inner);
	--global-background: var(--dark-gray-hex);
	// Buttons ========================================================================== */
	--button-border-color: var(--white-hex);
	--button-background: var(--white-hex);
	--button-background-hover: rgba(var(--white-rgb), 0.7);
	--button-color: var(--white-inner);

	--button-primary-border-color: var(--white-hex);
	--button-primary-background: var(--white-hex);
	--button-primary-background-hover: rgba(var(--white-rgb), 0.7);
	--button-primary-color: var(--white-inner);
	//Cards
	--card-background: var(--black-hex);
}
