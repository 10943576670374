.container {
	position: relative;
	z-index: var(--header-z-index);
	transform: translate(0);
	transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	background: var(--global-background);
	&[data-hide="true"] {
		transform: translateY(-100%);
	}
	&[data-at-top="false"] {
		position: fixed;
	}
}

.content {
	padding: 0 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: var(--header-height);
}
.link {
	display: inline-flex;
}
