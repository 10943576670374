.banner {
	max-width: 20rem;
	background: var(--spotify-hex);
	color: var(--spotify-inner);
	display: flex;
	justify-content: center;
	padding: 0.5rem;
	align-items: center;
	z-index: 1020;
	text-decoration: none;
	margin: 0.5rem 0;
	border-radius: 0.5rem;
	width: 100%;
}

.songTitle,
.subLabel {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 9rem;
}
.imageContainer {
	flex: 0 0 auto;
}
.animationContainer {
	flex: 0 0 auto;
}

.title {
	font-size: 0.7rem;
	margin-right: 0.5rem;
	flex: 0 0 auto;
}

.image {
	border-radius: 5rem;
}

$max: 1.5rem;

.content {
	line-height: 1em;
	font-size: 0.75rem;
	display: inline-flex;
	flex-direction: column;
	margin: 0 1rem;
}

.equilizer {
	height: $max;
	width: $max;
	transform: rotate(180deg);
}

.bar {
	fill: #fff;
	width: 10px;
	border-radius: 2rem;
	animation: equalize 4s 0s infinite;
}

.bar:nth-child(1) {
	animation-delay: -1.9s;
}

.bar:nth-child(2) {
	animation-delay: -2s;
}

.bar:nth-child(3) {
	animation-delay: -2.3s;
}

.bar:nth-child(4) {
	animation-delay: -2.4s;
}

.bar:nth-child(5) {
	animation-delay: -2.1s;
}

@keyframes equalize {
	0% {
		height: 60px;
	}
	4% {
		height: 50px;
	}
	8% {
		height: 40px;
	}
	12% {
		height: 30px;
	}
	16% {
		height: 20px;
	}
	20% {
		height: 30px;
	}
	24% {
		height: 40px;
	}
	28% {
		height: 10px;
	}
	32% {
		height: 40px;
	}
	36% {
		height: 60px;
	}
	40% {
		height: 20px;
	}
	44% {
		height: 40px;
	}
	48% {
		height: 70px;
	}
	52% {
		height: 30px;
	}
	56% {
		height: 10px;
	}
	60% {
		height: 30px;
	}
	64% {
		height: 50px;
	}
	68% {
		height: 60px;
	}
	72% {
		height: 70px;
	}
	76% {
		height: 80px;
	}
	80% {
		height: 70px;
	}
	84% {
		height: 60px;
	}
	88% {
		height: 50px;
	}
	92% {
		height: 60px;
	}
	96% {
		height: 70px;
	}
	100% {
		height: 80px;
	}
}
