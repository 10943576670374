.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: var(--button-padding);
	border-radius: var(--button-radius);
	height: var(--button-height);
	border: var(--button-border) var(--button-border-color);
	background: var(--button-background);
	color: var(--button-color);
	cursor: pointer;
	width: var(--button-width);
	margin: var(--button-margin);
	text-decoration: none;
	font-weight: 600;
	&:hover {
		background: var(--button-background-hover);
	}
	svg {
		fill: var(--button-color);
		font-size: 1.25em;
	}
	&[disabled] {
		cursor: not-allowed;
		pointer-events: none;
		opacity: 0.5;
	}
}
.primary {
	--button-border-color: var(--button-primary-border-color);
	--button-background: var(--button-primary-background);
	--button-background-hover: var(--button-background-hover);
	--button-color: var(--button-primary-color);
}

.blank {
	--button-border-color: transparent;
	background: transparent;
	--button-background-hover: transparent;
	--button-color: var(--button-background);
}
.iconOnly {
	--button-width: var(--button-height);
	--button-padding: 0;
}
.fullWidth {
	--button-width: 100%;
}
.noSpacing {
	--button-margin: 0;
}
.circle {
	--button-width: var(--button-height);
	--button-padding: 0;
	--button-radius: 3em;
}
