.heading {
	padding: 1rem;
	cursor: pointer;
	font-size: 1.3rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: transparent;
	width: 100%;
	border: none;
	color: inherit;
}
.icon {
	transition: all 0.2s;
	&[data-open="true"] {
		transform: rotate(180deg);
	}
}
