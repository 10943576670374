.nav {
	display: none;
	@media (--md-breakpoint) {
		display: inherit;
	}
}

.navList {
	@util reset-list;
	display: flex;

	&[data-mobile] {
		flex-direction: column;
	}
}

.navItem {
	padding: 0 1rem;
	display: flex;
	margin-bottom: 1rem;
	font-size: 1.2rem;
	@media (--md-breakpoint) {
		font-size: 1rem;
		margin-bottom: 0;
	}
}

.link {
	composes: resetLink from "../../styles/_utils.module.scss";
}

.mobileNav {
	@media (--md-breakpoint) {
		display: none;
	}
	position: fixed;
	top: var(--header-height);
	left: 0;
	bottom: 0;
	width: 100%;
	height: calc(100vh - var(--header-height));
	background: var(--global-background);
}

.menuButton {
	position: relative;
	--button-height: 2rem;
	@media (--md-breakpoint) {
		display: none !important;
	}
	&:before,
	&:after {
		transition: 0.25s;
		content: "";
		width: 60%;
		border-radius: 1em;
		height: 2px;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		background: var(--global-font-color);
	}

	&:before {
		top: calc(40% - 2px);
	}

	&:after {
		bottom: calc(40% - 2px);
	}

	&[data-show-nav="true"] {
		&:before,
		&:after {
			top: 50%;
		}

		&:before {
			transform: translateX(-50%) rotate(45deg);
		}

		&:after {
			transform: translateX(-50%) rotate(-45deg);
		}
	}
}
