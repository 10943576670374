.logo {
	width: 5rem;
	display: inline-flex;
	svg {
		width: 100%;
	}
}
.sm {
	width: 2rem;
}
.lg {
	width: 8rem;
}
