.card {
	overflow: hidden;
	background: var(--card-background);
	border-radius: var(--card-border-radius);
	composes: resetLink from "../../styles/_utils.module.scss";
	&:hover {
		.image {
			transform: scale(1.05);
		}
	}
}
.content {
	padding: 1rem;
}
.headerLabel {
	color: var(--card-sub-headline-color);
	font-weight: 800;
	margin-bottom: 0.5rem;
}
.title {
}
.subTitle {
	color: var(--card-sub-headline-color);
	font-weight: 600;
}
.image {
	transition: all 0.5s;
}
