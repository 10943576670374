.input {
	background: inherit;
	color: var(--input-color);
	border: 1px solid var(--input-border-color);
	padding: 0.5rem 1rem;
	border-radius: var(--input-border-radius);
	&:focus {
		outline: none;
	}
}
